<template>
  <div>
    <!-- 路由出口 -->
    <!-- 路由匹配到的组件将渲染在这里 -->
    <!-- <router-view></router-view>
    <tab-bar/>-->
    <router-view/>
    <van-tabbar v-show="hidShow" route :border="false" active-color="#F5A623" inactive-color="#fff" style="font-size: 18px;background: #760D18;border-radius: 10px 10px 0 0; ">
      <van-tabbar-item replace to="/main/center" >中奖记录</van-tabbar-item>
      <van-tabbar-item replace to="/main/about" >产品介绍</van-tabbar-item>
      <van-tabbar-item replace to="/main/message" >我要留言</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
// import TabBar from "@/components/TabBar.vue";
export default {
  components: {
    // TabBar
  },
  computed: {
    hidShow() {
      return this.$store.getters.hidShow 
    }
  },
  created () {
    let openId = localStorage.getItem("openId");

    if (openId && openId != "") {
      this.userInfo = JSON.parse(localStorage.getItem("wxUserInfo"));
      this.$store.dispatch(
        "setUserInfo",
        JSON.parse(localStorage.getItem("wxUserInfo"))
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.van-tabbar{
  height: 65px;
}
.van-tabbar-item {
  font-size: 18px !important;
}
.van-tabbar-item--active{
  background-color: #760D18 !important;
  border-radius: 10px 10px 0 0 !important;
}
</style>